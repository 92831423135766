import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import SEO from '../../components/seo'
import SubPageLayout from '../../layouts/subPageLayout'
import PageSection from '../../common/section'

const MobilityWarrantyPage = () => {
  const data = useStaticQuery(graphql`
    query {
      mg: file(relativePath: { eq: "mobilitetsgaranti.png" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      viking: file(relativePath: { eq: "falck_redningsbil_02.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const title = 'Service med mobilitetsgaranti'
  const description1 =
    'Hos BilXtra verksted får du 12 måneders Mobilitetsgaranti med på kjøpet, hvis du bestiller BilXtra – service med Mobilitetsgaranti, eller service ut fra bilens servicehefte.'
  const description2 =
    'Med BilXtra Mobilitetsgaranti får du hjelp uansett tid og sted i  hele Norden i et helt år. Ved å utføre service hos BilXtra verksted, vil Mobilitetsgarantien fornyes med 12 måneder og gi deg ekstra trygghet.'
  return (
    <SubPageLayout title={title} description1={description1} description2={description2}>
      <SEO title={title} />
      <PageSection
        title="BilXtra Mobilitetsgaranti – hjelp når du trenger det"
        image={data.mg.childImageSharp.fluid}
      >
        <p>
          Din bil vil ikke starte, eller får driftsstans under kjøring? Du har hatt et uhell –
          utforkjøring eller kollisjon? Du har vært uheldig og låst deg ute av bilen? Bilnøkkelen er
          ødelagt eller mistet? Bilen har gått tom for drivstoff? Det er fylt feil drivstoff på
          tanken? Du har vært uheldig og punktert og trenger hjelp til å bytte dekk?
        </p>
      </PageSection>

      <PageSection title="Dine fordeler" image={data.viking.childImageSharp.fluid} imageLeft={true}>
        <ul>
          <li>Ingen egenandel.</li>
          <li>Hjelp på stedet eller på din hjemstedsadresse.</li>
          <li>Gratis transport til nærmeste BilXtra verksted.</li>
          <li>Alternativt ett døgns overnatting for deg og dine passasjerer.</li>
          <li>Assistanse 24 timer i døgnet, alle dager i året, i hele Norden.</li>
        </ul>
      </PageSection>
    </SubPageLayout>
  )
}

export default MobilityWarrantyPage

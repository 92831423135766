import { graphql, Link, useStaticQuery, navigate } from 'gatsby'
import React, { useState } from 'react'
import Img from 'gatsby-image'
import useOnclickOutside from 'react-cool-onclickoutside'
import { faSortDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useLogout } from '../hooks/use-logout'
import {
  NavBar,
  NavBarBrand,
  NavBarBrandImage,
  NavBarContainer,
  NavBarItem,
  NavBarLeft,
  NavBarMenuButton,
  NavBarMobile,
  NavBarMobileContainer,
  NavBarRight,
  NavMobile,
  NavDropDown,
  NavDropDownContent,
  NavBarPrimary,
  ButtonBilxtra,
  NavbarHedingImg,
  SubNavBarRight,
  SubNavBarItem,
} from './styled/navBar'

const SubPageHeader = ({ token }) => {
  const [navIsOpen, setNavIsOpen] = useState(false)

  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "bilxtra-logo.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const { logout } = useLogout()

  const toggleNav = () => setNavIsOpen(!navIsOpen)

  const doLogout = async () => {
    await logout()
    await navigate(`/`)
  }

  const [openMenu2, setOpenMenu2] = useState(false)
  const [openMenu3, setOpenMenu3] = useState(false)

  const handleBtnClick2 = () => setOpenMenu2(!openMenu2)
  const handleBtnClick3 = () => setOpenMenu3(!openMenu3)
  const closeMenu2 = () => setOpenMenu2(false)
  const closeMenu3 = () => setOpenMenu3(false)
  const ref2 = useOnclickOutside(() => closeMenu2())
  const ref3 = useOnclickOutside(() => closeMenu3())
  //s
  const linksLeft = (
    <React.Fragment>
      <NavDropDown className="dropdown">
        <NavBarItem>
          <Link to="/#oversikt">
            Finn verksted
            <FontAwesomeIcon
              icon={faSortDown}
              style={{ float: 'right', margin: '3px', color: 'white' }}
            />
          </Link>
        </NavBarItem>
      </NavDropDown>
      <NavDropDown className="dropdown" ref={ref2}>
        <NavBarItem className="dropdown-btn" onClick={handleBtnClick2} type="button">
          <Link>
            Fordeler & garanti
            <FontAwesomeIcon icon={faSortDown} style={{ float: 'right', margin: '3px' }} />
          </Link>
        </NavBarItem>
        {openMenu2 && (
          <NavDropDownContent className="dropdown-menu" onClick={closeMenu2}>
            <Link to="/mulighet-bilxtra/Veihjelp">Veihjelp</Link>
            <Link to="/mulighet-bilxtra/delegaranti">Delegaranti</Link>
            <Link to="/mulighet-bilxtra/Fritt-verkstedvalg">Fritt verkstedvalg</Link>
            <Link to="/mulighet-bilxtra/bilxtra-konto">BilXtra Konto</Link>
            <Link to="/mulighet-bilxtra/Digitalservicehefte">Digitalservicehefte</Link>
            <Link to="/#omoss">Om Bilxtra</Link>
          </NavDropDownContent>
        )}
      </NavDropDown>
      <NavDropDown className="dropdown" ref={ref3}>
        <NavBarItem className="dropdown-btn" onClick={handleBtnClick3} type="button">
          <Link>
            Service & reparasjon
            <FontAwesomeIcon icon={faSortDown} style={{ float: 'right', margin: '3px' }} />
          </Link>
        </NavBarItem>
        {openMenu3 && (
          <NavDropDownContent className="dropdown-menu" onClick={closeMenu3}>
            <Link to="/service-og-reparasjon/EU-kontroll">EU kontroll</Link>
            <Link to="/service-og-reparasjon/air-condition"> AC Service og Klimaservice</Link>
            <Link to="/service-og-reparasjon/hjulstillingskontroll">Hjulstillingskontroll</Link>
            <Link to="/service-og-reparasjon/el-bil">El-bil & hybrid</Link>
            <Link to="/service-og-reparasjon/reparasjon-av-alle-bilmerker">
              Reparasjon av alle bilmerker
            </Link>
            <Link to="/service-og-reparasjon/motor-og-service">Motor – service</Link>
            <Link to="/service-og-reparasjon/clutch-og-brems">Clutch og brems</Link>
            <Link to="/service-og-reparasjon/diagnose-og-systemscan">Diagnose og systemscan</Link>
            <Link to="/service-og-reparasjon/Oljeskift">Oljeskift</Link>
            <Link to="/service-og-reparasjon/reparasjon-av-tilhengere">
              Reparasjon av tilhengere
            </Link>
          </NavDropDownContent>
        )}
      </NavDropDown>
      <NavBarItem>
        <Link to="/service-og-reparasjon/el-bil">El-bil & hybrid</Link>
      </NavBarItem>
    </React.Fragment>
  )
  const linksRight = (
    <React.Fragment>
      <NavBarItem>
        {token && (
          <button type="button" onClick={doLogout}>
            Logg ut
          </button>
        )}
        {!token && <Link to="/login">Bileiers side</Link>}
      </NavBarItem>
      <NavBarItem>
        <ButtonBilxtra>
          <a href="https://www.bilxtra.no" target="_blank" rel="noreferrer">
            Bilxtra.no
          </a>
        </ButtonBilxtra>
      </NavBarItem>
    </React.Fragment>
  )

  const bottomLinks = (
    <React.Fragment>
      <NavBarItem>
        <Link to="/job-vacancy">JOBBE HOS OSS</Link>
      </NavBarItem>
    </React.Fragment>
  )

  return (
    <React.Fragment>
      <NavBar>
        <NavBarContainer>
          <NavBarBrandImage>
            <Link to="/#">
              <Img fluid={data.logo.childImageSharp.fluid} alt="BilXtra verksted" />
            </Link>
          </NavBarBrandImage>
          <NavBarLeft>{linksLeft}</NavBarLeft>
          <NavBarRight>{linksRight}</NavBarRight>
        </NavBarContainer>
        <NavBarPrimary>
          <SubNavBarRight>
            <SubNavBarItem>
              <Link className="jobehososs" to="/job-vacancy">
                JOBBE HOS OSS
              </Link>
            </SubNavBarItem>
          </SubNavBarRight>
        </NavBarPrimary>
      </NavBar>
      <NavBarMobile>
        <NavBarMobileContainer>
          <NavBarBrand>
            <Link to="/#">
              <NavbarHedingImg>
                <Img fluid={data.logo.childImageSharp.fluid} />
              </NavbarHedingImg>
            </Link>
          </NavBarBrand>
          <NavBarRight>
            <NavBarMenuButton onClick={toggleNav}>
              <div></div>
              <div></div>
              <div></div>
            </NavBarMenuButton>
          </NavBarRight>
        </NavBarMobileContainer>
        <NavMobile className={navIsOpen ? 'isOpen' : 'isClosed'}>
          {linksLeft}
          {linksRight}
          {bottomLinks}
        </NavMobile>
      </NavBarMobile>
    </React.Fragment>
  )
}

export default SubPageHeader

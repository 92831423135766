export const useLogout = () => {
  const logout = () => {
    return new Promise((resolve) => {
      localStorage.setItem('bilxtra-web-auth', null)
      return resolve()
    })
  }

  return { logout }
}

import { Section, SectionParagraph, SectionTitle } from '../components/styled/section'
import { Column1, GridWithGap } from '../components/styled/grid'
import Img from 'gatsby-image'
import React from 'react'

const PageSection = ({ imageLeft = false, title, children, image }) => {
  if (imageLeft) {
    return (
      <Section>
        <SectionTitle>{title}</SectionTitle>
        <GridWithGap>
          <Column1>
            <Img fluid={image} />
          </Column1>
          <Column1>
            <SectionParagraph>{children}</SectionParagraph>
          </Column1>
        </GridWithGap>
      </Section>
    )
  } else {
    return (
      <Section>
        <SectionTitle>{title}</SectionTitle>
        <GridWithGap>
          <Column1>
            <SectionParagraph>{children}</SectionParagraph>
          </Column1>
          <Column1>
            <Img fluid={image} />
          </Column1>
        </GridWithGap>
      </Section>
    )
  }
}

export default PageSection
